:root {
  --md-source: #00b9bc;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary4: #001111;
  --md-ref-palette-primary5: #001415;
  --md-ref-palette-primary6: #001717;
  --md-ref-palette-primary10: #002020;
  --md-ref-palette-primary12: #002425;
  --md-ref-palette-primary17: #003031;
  --md-ref-palette-primary20: #003738;
  --md-ref-palette-primary22: #003c3d;
  --md-ref-palette-primary24: #004042;
  --md-ref-palette-primary25: #004344;
  --md-ref-palette-primary30: #004f51;
  --md-ref-palette-primary35: #005c5e;
  --md-ref-palette-primary40: #00696b;
  --md-ref-palette-primary50: #008587;
  --md-ref-palette-primary60: #00a1a4;
  --md-ref-palette-primary70: #16bec1;
  --md-ref-palette-primary80: #49dadd;
  --md-ref-palette-primary87: #62eef1;
  --md-ref-palette-primary90: #6cf7f9;
  --md-ref-palette-primary92: #74fcff;
  --md-ref-palette-primary94: #a1fdff;
  --md-ref-palette-primary95: #b3feff;
  --md-ref-palette-primary96: #c4feff;
  --md-ref-palette-primary98: #e2ffff;
  --md-ref-palette-primary99: #f1ffff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary4: #001111;
  --md-ref-palette-secondary5: #001415;
  --md-ref-palette-secondary6: #001717;
  --md-ref-palette-secondary10: #041f20;
  --md-ref-palette-secondary12: #092324;
  --md-ref-palette-secondary17: #142e2e;
  --md-ref-palette-secondary20: #1b3435;
  --md-ref-palette-secondary22: #203939;
  --md-ref-palette-secondary24: #243d3e;
  --md-ref-palette-secondary25: #274040;
  --md-ref-palette-secondary30: #324b4b;
  --md-ref-palette-secondary35: #3e5757;
  --md-ref-palette-secondary40: #4a6363;
  --md-ref-palette-secondary50: #627c7c;
  --md-ref-palette-secondary60: #7b9696;
  --md-ref-palette-secondary70: #95b0b0;
  --md-ref-palette-secondary80: #b0cccc;
  --md-ref-palette-secondary87: #c4dfdf;
  --md-ref-palette-secondary90: #cce8e8;
  --md-ref-palette-secondary92: #d2eeee;
  --md-ref-palette-secondary94: #d7f3f3;
  --md-ref-palette-secondary95: #daf6f6;
  --md-ref-palette-secondary96: #ddf9f9;
  --md-ref-palette-secondary98: #e3ffff;
  --md-ref-palette-secondary99: #f1ffff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary4: #000e23;
  --md-ref-palette-tertiary5: #001127;
  --md-ref-palette-tertiary6: #00142b;
  --md-ref-palette-tertiary10: #051c35;
  --md-ref-palette-tertiary12: #0a203a;
  --md-ref-palette-tertiary17: #162b45;
  --md-ref-palette-tertiary20: #1d314c;
  --md-ref-palette-tertiary22: #223650;
  --md-ref-palette-tertiary24: #273a55;
  --md-ref-palette-tertiary25: #293c57;
  --md-ref-palette-tertiary30: #344863;
  --md-ref-palette-tertiary35: #405370;
  --md-ref-palette-tertiary40: #4c5f7c;
  --md-ref-palette-tertiary50: #657896;
  --md-ref-palette-tertiary60: #7e92b1;
  --md-ref-palette-tertiary70: #99accc;
  --md-ref-palette-tertiary80: #b4c8e9;
  --md-ref-palette-tertiary87: #c7dbfd;
  --md-ref-palette-tertiary90: #d4e3ff;
  --md-ref-palette-tertiary92: #dde9ff;
  --md-ref-palette-tertiary94: #e6eeff;
  --md-ref-palette-tertiary95: #ebf1ff;
  --md-ref-palette-tertiary96: #eff3ff;
  --md-ref-palette-tertiary98: #f9f9ff;
  --md-ref-palette-tertiary99: #fdfcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral4: #0b0f0f;
  --md-ref-palette-neutral5: #0e1212;
  --md-ref-palette-neutral6: #101414;
  --md-ref-palette-neutral10: #191c1c;
  --md-ref-palette-neutral12: #1d2020;
  --md-ref-palette-neutral17: #272b2b;
  --md-ref-palette-neutral20: #2d3131;
  --md-ref-palette-neutral22: #323535;
  --md-ref-palette-neutral24: #363a3a;
  --md-ref-palette-neutral25: #383c3c;
  --md-ref-palette-neutral30: #444747;
  --md-ref-palette-neutral35: #505353;
  --md-ref-palette-neutral40: #5b5f5f;
  --md-ref-palette-neutral50: #747877;
  --md-ref-palette-neutral60: #8e9191;
  --md-ref-palette-neutral70: #a9acab;
  --md-ref-palette-neutral80: #c4c7c6;
  --md-ref-palette-neutral87: #d8dada;
  --md-ref-palette-neutral90: #e0e3e2;
  --md-ref-palette-neutral92: #e6e9e8;
  --md-ref-palette-neutral94: #eceeee;
  --md-ref-palette-neutral95: #eff1f0;
  --md-ref-palette-neutral96: #f2f4f3;
  --md-ref-palette-neutral98: #f7faf9;
  --md-ref-palette-neutral99: #fafdfc;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant4: #071010;
  --md-ref-palette-neutral-variant5: #091313;
  --md-ref-palette-neutral-variant6: #0c1515;
  --md-ref-palette-neutral-variant10: #141d1d;
  --md-ref-palette-neutral-variant12: #182121;
  --md-ref-palette-neutral-variant17: #222c2c;
  --md-ref-palette-neutral-variant20: #293232;
  --md-ref-palette-neutral-variant22: #2d3737;
  --md-ref-palette-neutral-variant24: #313b3b;
  --md-ref-palette-neutral-variant25: #343d3d;
  --md-ref-palette-neutral-variant30: #3f4949;
  --md-ref-palette-neutral-variant35: #4a5454;
  --md-ref-palette-neutral-variant40: #566060;
  --md-ref-palette-neutral-variant50: #6f7979;
  --md-ref-palette-neutral-variant60: #899392;
  --md-ref-palette-neutral-variant70: #a3adad;
  --md-ref-palette-neutral-variant80: #bec8c8;
  --md-ref-palette-neutral-variant87: #d2dcdc;
  --md-ref-palette-neutral-variant90: #dae4e4;
  --md-ref-palette-neutral-variant92: #e0eaea;
  --md-ref-palette-neutral-variant94: #e6f0ef;
  --md-ref-palette-neutral-variant95: #e9f3f2;
  --md-ref-palette-neutral-variant96: #ebf6f5;
  --md-ref-palette-neutral-variant98: #f1fbfb;
  --md-ref-palette-neutral-variant99: #f4fefe;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error4: #280001;
  --md-ref-palette-error5: #2d0001;
  --md-ref-palette-error6: #310001;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error12: #490002;
  --md-ref-palette-error17: #5c0004;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error22: #710005;
  --md-ref-palette-error24: #790006;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error87: #ffcfc9;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error92: #ffe2de;
  --md-ref-palette-error94: #ffe9e6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error96: #fff0ee;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #00696b;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #6cf7f9;
  --md-sys-color-on-primary-container-light: #002020;
  --md-sys-color-primary-fixed-light: #6cf7f9;
  --md-sys-color-on-primary-fixed-light: #002020;
  --md-sys-color-primary-fixed-dim-light: #49dadd;
  --md-sys-color-on-primary-fixed-variant-light: #004f51;
  --md-sys-color-secondary-light: #4a6363;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #cce8e8;
  --md-sys-color-on-secondary-container-light: #041f20;
  --md-sys-color-secondary-fixed-light: #cce8e8;
  --md-sys-color-on-secondary-fixed-light: #041f20;
  --md-sys-color-secondary-fixed-dim-light: #b0cccc;
  --md-sys-color-on-secondary-fixed-variant-light: #324b4b;
  --md-sys-color-tertiary-light: #4c5f7c;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #d4e3ff;
  --md-sys-color-on-tertiary-container-light: #051c35;
  --md-sys-color-tertiary-fixed-light: #d4e3ff;
  --md-sys-color-on-tertiary-fixed-light: #051c35;
  --md-sys-color-tertiary-fixed-dim-light: #b4c8e9;
  --md-sys-color-on-tertiary-fixed-variant-light: #344863;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fafdfc;
  --md-sys-color-on-background-light: #191c1c;
  --md-sys-color-outline-light: #6f7979;
  --md-sys-color-inverse-on-surface-light: #eff1f0;
  --md-sys-color-inverse-surface-light: #2d3131;
  --md-sys-color-inverse-primary-light: #49dadd;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #00696b;
  --md-sys-color-outline-variant-light: #bec8c8;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-surface-light: #f7faf9;
  --md-sys-color-on-surface-light: #191c1c;
  --md-sys-color-surface-variant-light: #dae4e4;
  --md-sys-color-on-surface-variant-light: #3f4949;
  --md-sys-color-surface-container-highest-light: #e0e3e2;
  --md-sys-color-surface-container-high-light: #e6e9e8;
  --md-sys-color-surface-container-light: #eceeee;
  --md-sys-color-surface-container-low-light: #f2f4f3;
  --md-sys-color-surface-container-lowest-light: #ffffff;
  --md-sys-color-surface-dim-light: #d8dada;
  --md-sys-color-surface-bright-light: #f7faf9;
  /* dark */
  --md-sys-color-primary-dark: #49dadd;
  --md-sys-color-on-primary-dark: #003738;
  --md-sys-color-primary-container-dark: #004f51;
  --md-sys-color-on-primary-container-dark: #6cf7f9;
  --md-sys-color-primary-fixed-dark: #6cf7f9;
  --md-sys-color-on-primary-fixed-dark: #002020;
  --md-sys-color-primary-fixed-dim-dark: #49dadd;
  --md-sys-color-on-primary-fixed-variant-dark: #004f51;
  --md-sys-color-secondary-dark: #b0cccc;
  --md-sys-color-on-secondary-dark: #1b3435;
  --md-sys-color-secondary-container-dark: #324b4b;
  --md-sys-color-on-secondary-container-dark: #cce8e8;
  --md-sys-color-secondary-fixed-dark: #cce8e8;
  --md-sys-color-on-secondary-fixed-dark: #041f20;
  --md-sys-color-secondary-fixed-dim-dark: #b0cccc;
  --md-sys-color-on-secondary-fixed-variant-dark: #324b4b;
  --md-sys-color-tertiary-dark: #b4c8e9;
  --md-sys-color-on-tertiary-dark: #1d314c;
  --md-sys-color-tertiary-container-dark: #344863;
  --md-sys-color-on-tertiary-container-dark: #d4e3ff;
  --md-sys-color-tertiary-fixed-dark: #d4e3ff;
  --md-sys-color-on-tertiary-fixed-dark: #051c35;
  --md-sys-color-tertiary-fixed-dim-dark: #b4c8e9;
  --md-sys-color-on-tertiary-fixed-variant-dark: #344863;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #191c1c;
  --md-sys-color-on-background-dark: #e0e3e2;
  --md-sys-color-outline-dark: #899392;
  --md-sys-color-inverse-on-surface-dark: #191c1c;
  --md-sys-color-inverse-surface-dark: #e0e3e2;
  --md-sys-color-inverse-primary-dark: #00696b;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #49dadd;
  --md-sys-color-outline-variant-dark: #3f4949;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-surface-dark: #101414;
  --md-sys-color-on-surface-dark: #c4c7c6;
  --md-sys-color-surface-variant-dark: #3f4949;
  --md-sys-color-on-surface-variant-dark: #bec8c8;
  --md-sys-color-surface-container-highest-dark: #323535;
  --md-sys-color-surface-container-high-dark: #272b2b;
  --md-sys-color-surface-container-dark: #1d2020;
  --md-sys-color-surface-container-low-dark: #191c1c;
  --md-sys-color-surface-container-lowest-dark: #0b0f0f;
  --md-sys-color-surface-dim-dark: #101414;
  --md-sys-color-surface-bright-dark: #363a3a;
  /* Custom Colors */
  --md-custom-color-00b9bc: #00b9bc;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
