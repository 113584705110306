@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/sass/themes/theme.css';
@import './assets/sass/variables.scss';
/* You can add global styles to this file, and also import other style files */

@layer base {
  .striped div:nth-child(even) {
    @apply bg-gray-100
  }

  :root {
    --color-primary-rgb: 2 48 71;
    --color-secondary-rgb: 253 240 213;
    --color-tertiary-rgb: 102 155 188;

    --color-primary: rgb(var(--color-primary-rgb));
    --color-secondary: rgb(var(--color-secondary-rgb));
    --color-tertiary: rgb(var(--color-tertiary-rgb));
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: yellow !important;
  }

  hidden-fillter {
    @apply w-24
  }

  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }

  button:not(.icon):not([mat-icon]):not([icon-button]):not(.mat*) {
    @apply bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-full min-w-[8rem]
    flex items-center justify-center
    space-x-4
  }

  .button-primary {
    @apply bg-secondary hover:bg-primary text-white font-bold py-2 px-4 rounded-full flex items-center justify-center
    space-x-4
  }

  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }

  md-filled-button {
    @apply h-9
  }

  md-filled-button div {
    @apply
    space-x-2 flex items-center justify-center
  }

  md-filled-icon-button, md-icon-button {
    @apply font-bold py-2 px-4 rounded-full min-w-[2rem]
    items-center justify-center align-middle
  }

  md-filled-icon-button {
    @apply bg-primary hover:bg-primary/50
  }

  md-divider {
    @apply m-3
  }

  a {
    @apply hover:underline text-tertiary
  }

  .error-text {
    @apply text-red-500
  }
}

.app-color-picker .preview .circle {
  height: 60px;
  width: 60px;
}

md-outlined-text-field {
  border: white !important;
}

#avatar-icon {
  height: auto;
  width: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: theme('colors.primary');
}

md-icon {
  --md-icon-size: 24px;
  --md-icon-font: 'Material Symbols Rounded';
}

.rounded {
  --md-icon-font: 'Material Symbols Rounded';
}

.sharp {
  --md-icon-font: 'Material Symbols Sharp';
}

md-icon[filled] {
  font-variation-settings: 'FILL' 1;
}

.custom-style {
  background-color: brown;
  color: white;
}

/* Snackbar styling */
.success-snackbar {
  color: white;
  --mat-snack-bar-button-color: white;
  --mdc-snackbar-container-color: theme('colors.primary');
  white-space: pre-wrap;
}

.warning-snackbar {
  color: var(--primary);
  --mat-snack-bar-button-color: var(--primary);
  --mdc-snackbar-container-color: theme(colors.yellow.500);
  white-space: pre-wrap;
}

.error-snackbar {
  color: white;
  --mdc-snackbar-container-color: theme(colors.red.600);
  --mat-snack-bar-button-color: white;
  white-space: pre-wrap;
}

.warning {
  @apply text-red-600 font-bold
}

mat-expansion-panel {
  mat-expansion-panel-header {
    height: auto !important;
  }
}

.input-wrapper {
  padding-top: 8px;
}

/****************************/
/* Overwrite default styles */
/****************************/

[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: initial;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/
    /*!*/
  );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1C64F2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border: none;
  outline-offset: initial;
  outline: none;
  @apply ring-0 border-none;
}

select:not([size]) {
  background: none;
  padding-right: inherit;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

th {
  @apply font-bold text-left
}

.menu-item {
  @apply flex items-center gap-3 p-3 text-base font-normal
  text-gray-900
  rounded-lg
  hover:bg-gray-100
  hover:no-underline
  cursor-pointer
  ;
}

.subdetail {
  @apply italic text-gray-500
}

.mat-step-label.mat-step-label {
  text-overflow: inherit;
  white-space: normal;
}
