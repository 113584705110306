:root {
  --primary: theme('colors.primary');
  --secondary: theme('colors.secondary');
  --tertiary: theme('colors.tertiary');
  --md-sys-color-primary: theme('colors.primary');
  --md-sys-color-on-primary: theme('colors.white.50');
  --md-ref-typeface-brand: Roboto;
  --md-ref-typeface-plain: Roboto;

  --md-sys-color-secondary-container: var('colors.primary'/80);

  --mat-sidenav-content-text-color: var(--color-primary);

  --md-filled-icon-button-container-width: 40px;
  --md-filled-icon-button-container-height: 40px;
  --md-filled-icon-button-container-shape: 15px;
  --md-filled-icon-button-icon-size: 24px;

  --md-filled-tonal-icon-button-container-width: 40px;
  --md-filled-tonal-icon-button-container-height: 40px;
  --md-filled-tonal-icon-button-container-shape: 15px;
  --md-filled-tonal-icon-button-icon-size: 24px;

  --md-sys-color-outline: rgba(0, 0, 0, 0.12);
  --md-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.50);

  --md-filled-button-container-height: 20px;

  --mat-stepper-header-selected-state-icon-background-color: theme('colors.primary');
  --mat-stepper-header-edit-state-icon-background-color: theme('colors.primary');
  --mat-stepper-header-done-state-icon-background-color: theme('colors.primary');
  --mat-stepper-header-selected-state-label-text-size: var(--mat-stepper-header-label-text-size);

  --mdc-filled-text-field-error-label-text-color: rgb(186, 26, 26);
  --mat-form-field-error-text-color: rgb(186, 26, 26);

  --md-sys-color-surface-container-high: theme('colors.white.100');
  --md-dialog-headline-color: theme('colors.primary');
  --mdc-circular-progress-active-indicator-color: theme('colors.primary');

  --mat-dialog-headline-padding: 1.5rem 1.5rem 1rem;
  --mat-dialog-actions-padding: 1rem 1rem 1rem;
  --mat-dialog-with-actions-content-padding: 1rem;
  --mdc-dialog-subhead-color: theme('colors.primary');
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-size: var(--md-dialog-headline-size, 1.5em);
  --mdc-dialog-subhead-weight: var(--md-dialog-headline-weight, 400);
  --mdc-dialog-supporting-text-size: var(--mdc-dialog-supporting-text-size);
  --mdc-dialog-container-shape: 24px;
  --mat-dialog-container-small-max-width: 90vw;

  // This is used in callout boxes
  --mdc-list-list-item-container-color: white;
}
