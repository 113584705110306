:root {
 --md-sys-color-primary: var(--md-sys-color-primary-light);
 --md-sys-color-on-primary: var(--md-sys-color-on-primary-light);
 --md-sys-color-primary-container: var(--md-sys-color-primary-container-light);
 --md-sys-color-on-primary-container: var(--md-sys-color-on-primary-container-light);
 --md-sys-color-primary-fixed: var(--md-sys-color-primary-fixed-light);
 --md-sys-color-on-primary-fixed: var(--md-sys-color-on-primary-fixed-light);
 --md-sys-color-primary-fixed-dim: var(--md-sys-color-primary-fixed-dim-light);
 --md-sys-color-on-primary-fixed-variant: var(--md-sys-color-on-primary-fixed-variant-light);
 --md-sys-color-secondary: var(--md-sys-color-secondary-light);
 --md-sys-color-on-secondary: var(--md-sys-color-on-secondary-light);
 --md-sys-color-secondary-container: var(--md-sys-color-secondary-container-light);
 --md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-light);
 --md-sys-color-secondary-fixed: var(--md-sys-color-secondary-fixed-light);
 --md-sys-color-on-secondary-fixed: var(--md-sys-color-on-secondary-fixed-light);
 --md-sys-color-secondary-fixed-dim: var(--md-sys-color-secondary-fixed-dim-light);
 --md-sys-color-on-secondary-fixed-variant: var(--md-sys-color-on-secondary-fixed-variant-light);
 --md-sys-color-tertiary: var(--md-sys-color-tertiary-light);
 --md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-light);
 --md-sys-color-tertiary-container: var(--md-sys-color-tertiary-container-light);
 --md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-light);
 --md-sys-color-tertiary-fixed: var(--md-sys-color-tertiary-fixed-light);
 --md-sys-color-on-tertiary-fixed: var(--md-sys-color-on-tertiary-fixed-light);
 --md-sys-color-tertiary-fixed-dim: var(--md-sys-color-tertiary-fixed-dim-light);
 --md-sys-color-on-tertiary-fixed-variant: var(--md-sys-color-on-tertiary-fixed-variant-light);
 --md-sys-color-error: var(--md-sys-color-error-light);
 --md-sys-color-error-container: var(--md-sys-color-error-container-light);
 --md-sys-color-on-error: var(--md-sys-color-on-error-light);
 --md-sys-color-on-error-container: var(--md-sys-color-on-error-container-light);
 --md-sys-color-background: var(--md-sys-color-background-light);
 --md-sys-color-on-background: var(--md-sys-color-on-background-light);
 --md-sys-color-outline: var(--md-sys-color-outline-light);
 --md-sys-color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-light);
 --md-sys-color-inverse-surface: var(--md-sys-color-inverse-surface-light);
 --md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-light);
 --md-sys-color-shadow: var(--md-sys-color-shadow-light);
 --md-sys-color-surface-tint: var(--md-sys-color-surface-tint-light);
 --md-sys-color-outline-variant: var(--md-sys-color-outline-variant-light);
 --md-sys-color-scrim: var(--md-sys-color-scrim-light);
 --md-sys-color-surface: var(--md-sys-color-surface-light);
 --md-sys-color-on-surface: var(--md-sys-color-on-surface-light);
 --md-sys-color-surface-variant: var(--md-sys-color-surface-variant-light);
 --md-sys-color-on-surface-variant: var(--md-sys-color-on-surface-variant-light);
 --md-sys-color-surface-container-highest: var(--md-sys-color-surface-container-highest-light);
 --md-sys-color-surface-container-high: var(--md-sys-color-surface-container-high-light);
 --md-sys-color-surface-container: var(--md-sys-color-surface-container-light);
 --md-sys-color-surface-container-low: var(--md-sys-color-surface-container-low-light);
 --md-sys-color-surface-container-lowest: var(--md-sys-color-surface-container-lowest-light);
 --md-sys-color-surface-dim: var(--md-sys-color-surface-dim-light);
 --md-sys-color-surface-bright: var(--md-sys-color-surface-bright-light);
}
